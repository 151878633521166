import React from 'react'
import styled from "@emotion/styled"
import { InstagramLogo, LinkedinLogo } from "phosphor-react";

import Title from './title'

const List = styled.ul`
li {
    a {
        border-bottom-color: transparent;
        &:hover {
            border-bottom-color: transparent;
        }
    }
}
dt {
    vertical-align: middle;
    margin-right: 15px;
}
dt, dd {
    display: inline-block;
}
@media (max-width: 599px) {
    dd {
        max-width: 80%;
        vertical-align: middle;
    }  
}
`

const Contactside = () => {
    return (
        <>
        <List>
        <Title>Connect</Title>
        <li><dl><a href="http://instagram.com/architecturefirm" target="_blank" rel="noreferrer" role="button" aria-label="Instagram"><dt><InstagramLogo size={48} weight="thin"/></dt><dd>Follow our latest work and news</dd></a></dl></li>
        <li><dl><a href="https://www.linkedin.com/company/architecturefirm" target="_blank" rel="noreferrer" role="button" aria-label="LinkedIn"><dt><LinkedinLogo size={48} weight="thin"/></dt><dd>Learn more about our team and job opportunities</dd></a></dl></li>
        </List>
        </>
    )
}

export default Contactside